import React, { useEffect } from 'react';
import { Footer, Blog, Possibility, Features, MetaflareCollections, Header, Faq, HeaderNew, Hero, HeroBody, AboutUs } from './containers';
import { CTA, Brand, Navbar, FormPage } from './components';
import './App.css';



const App = () => {
  return (
    <div>
        <div>
          {/* <Navbar /> */}
          {/* <Header /> */}
          <HeaderNew />
          <Hero/>
        </div>
        <div style={{ height: '70vh' }}>
          <HeroBody/>
        </div>
        <div>
          <MetaflareCollections />
          <Possibility />
          <AboutUs />
          <Faq />
          <Blog/>
          <Footer />
        </div>
      </div>
  )

}


export default App;