import React from 'react';
import './hero.css';

const Hero = () => {
  return (
    <div className="hero-container">
        <video autoPlay muted loop style={{ opacity: '60%' }}>
          <source src="high_fashion.mp4" type="video/mp4" />
        </video>
    </div>
  )
}

export default Hero;