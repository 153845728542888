import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CollectionPage from './CollectionPage';
import { Product, Navbar, RedemptionPage } from './components';
import { HeaderNew } from './containers'
import App from './App';

import './App.css';

const Layout = () => {
    return (
        <>
        <Router>
            <Routes>
                <Route exact path="/" element=
                    {<App />}/>
                <Route exact path="/collections" element=
                    {<CollectionPage/>}/>
                <Route exact path="/collections/:collectionId" element=
                    {<Product/>}/>
                <Route exact path="/redeem" element=
                    {<div>
                        <HeaderNew/>
                        <RedemptionPage/>
                    </div>
                }/>
            </Routes>
        </Router>
        </>
    )
}

export default Layout;