import React from 'react';
import { Typography, Card, CardMedia, CardContent} from '@material-ui/core'
import { Link } from 'react-router-dom';

import './Collection.css';
import useStyles from './styles';

const Collection = ({ collection }) => {
    const classes = useStyles();
    
    return (
        <>
            <div className={classes.collectionMain}>
                <div style={{ width: '75%'}}>
                    <Link key={collection.id} to={`/collections/${collection.id}`}>
                        <Card className={classes.collectionCard}>
                        <div>
                            <CardMedia className={classes.media} image={collection.url[0]} key={collection.id} />       
                        </div>
                        </Card>
                        <CardContent>
                            <Typography className={classes.collectionTitle} key={collection.name}>
                                {collection.name}
                            </Typography>
                        </CardContent>
                    </Link>
                </div>      
            </div>
        </>
    )
}

export default Collection; 