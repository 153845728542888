import React from 'react';
import { Typography, Card, Grid, CardMedia, CardContent, CardActions, Link, Icon, Box } from '@material-ui/core'

import CollectionsData from '../../data/data';

import './styles.css';
import Collection from './Collection';

const data = CollectionsData;

const Collections = () => {
    return (
        <>
            <div>
                <Box className="collections_list_header" sx={{ display: 'flex', justifyContent: 'center', marginTop: '2.5rem' }}>
                    <Typography style={{ fontSize: '36px', fontWeight: 'bold', color: 'white', fontFamily: 'var(--font-family)' }}>Explore Metaflare Collections</Typography>
                </Box>
                <Grid container spacing={4}>
                    {data?.collections?.map((collection) => (
                        <Grid item key={collection.id} xs={12} sm={6} md={4} lg={3}>
                            <Collection collection={collection} />
                        </Grid>
                    ))}
                </Grid>
            </div> 
        </>
    )
}

export default Collections; 