import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    featuredDiv: {
        // border: '20px solid rgba(9, 92, 117, 0.3)',
        border: '20px solid var(--color-bg2)',
        paddingBottom: '2.5rem',
        margin: '2rem 2rem 0 2rem'
    },
    // collectionMain: {
    //     width: '100%',
    //     marginTop: '2.5rem',
    //     display: 'flex',
    //     justifyContent: 'space-around',
    // },
    collectionCard: {
        display: 'flex',
        flexType: 'column',
        width: '100%',
        height: '376px',
        transition: 'transform 0.5s',
        borderRadius: '15px'
    },
    media: {
        cursor: 'pointer',
        height: '376px',
        width: '282px',
        borderRadius: '15px',
        objectFit: 'cover',

    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cardContent: {
        display: 'flex', 
        justifyContent: 'space-between',
    },
    collectionTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
        cursor: 'pointer',
        color: 'white',
    },
    collectionMain: {
        width: '100%',
        marginTop: '2.5rem',
        display: 'flex',
        justifyContent: 'space-around',
        flexType: 'row',
    },
    productPage: {
        width: '100%',
        height: '310px',
    }
}));