import React from 'react';
import './hero.css';
import { Link } from 'react-router-dom';


import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

const HeroBody = () => {
  return (
    <div className='body-container'>
      <ScrollAnimation animateIn="animate__slideInUp" animateOnce="true">
         <div className='text-container'>
             <h1 className="hero-text futura">
                Join the digital Renaissance <br/>
            </h1>          
            <div className='button-container'>
              <a href="https://www.niftygateway.com/publisher/metaflare" target="_blank" rel="noreferrer"><button>Mint on Nifty Gateway</button></a>
            </div>
         </div> 
      </ScrollAnimation>
    </div>
  )
}

export default HeroBody;




  