import React from 'react';
import { Card, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import './aboutUs.css';


const AboutUs = () => {
    const useStyles = makeStyles(() => ({
        about_paper: {
            backgroundColor: "rgba(0,0,0,0)"
        }
    }))
    const classes = useStyles();

    return (
        <div className='section__padding'>
            <Paper className={classes.about_paper} sx={{ height: '400px', width: '30%' }} elevation={2}>
                <h1 className="futura" style={{ color: 'white', marginLeft: '2rem', paddingTop: '2rem', fontSize: '48px' }}>
                    What We Do
                </h1>
                <div className="bios">
                    <Paper className={`bio-paper ${classes.about_paper}`}>
                        {/* <h1 className="futura" style={{ paddingtop: '2rem', marginLeft: '2rem', color: 'white' }}>Our Mission</h1> */}
                        <p className="futura" style={{ marginTop: '2rem', marginLeft: '2rem', marginRight: '2rem', marginBottom: '2rem', fontSize: '36px', color: 'white' }}>We are pushing the boundaries of what's possible with NFTs to empower artists, companies, and brands with the tools to participate in the digital renaissance.</p>
                    </Paper>
                </div>
            </Paper>
        </div> 
    )
}

export default AboutUs