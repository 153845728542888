import React from 'react';

import './redemptionPage.css';

const RedemptionPage = () => {
    return (
        <div className='futura redemption_header'>
            <div className='text_container'>
                <h1> 
                    Redeem Products
                </h1>
                <a href="https://forms.gle/tWNN9XgedbpRjLU47" rel="noreferrer" target="blank_"> 
                    <p style={{ color: 'blue' }}>
                        Fill out this form to redeem your real world utility
                    </p> 
                </a>
            </div>
        </div>
    )
}

export default RedemptionPage;