import React from 'react';
// import redImage from '../../assets/redblack.jpg';
// import watermelonImage from '../../assets/watermelon.jpg';
// import waveImage from '../../assets/wave.jpg';
import { Link } from 'react-router-dom';

import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="collections">
    <div className="gpt3__possibility-image">
      <img src="/metapuffs.png" alt="puffs" />
      <img src="gus.png" alt='gus'/>
      <img src="gus_metakicks.JPG" alt='kicks'/>
    </div>
    <div className="gpt3__possibility-content">
      <h4>Contact Us For Updates</h4>
      <h1 className="gradient__text">View our <br /> series of collections</h1>
      <p>View the works of the artist we have featured to see what possibilities there are.</p>
      <Link to="/collections"><button>Collections</button></Link>
    </div>
  </div> 
);

export default Possibility;