import React from 'react';
import { Collections, CollectionsHeader } from './components';
import { HeaderNew } from './containers';
import "./App.css";

const CollectionPage = () => (
    <div className='gradient__bg'>
        <HeaderNew/>
        <CollectionsHeader/>
        <Collections />
    </div>
)



export default CollectionPage;