import React from 'react';
import mfLogo from '../../logo.svg';
import './footer.css';

const Footer = () => (
  <div className="mf__footer section__padding" >
    <div className="mf__footer-heading">
      <h1 className="gradient__text">Powering the Digital Renaissance</h1>
    </div>
    <div className="footer_email"> 
      <a href="mailto:info@meta-flare.com">info@meta-flare.com</a>
    </div>
    <div className="mf__footer-copyright">
      <p>@2022 Meta-Flare. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;