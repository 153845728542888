import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import { Paper } from '@material-ui/core';
import './blog.css';

const BlogBox = ({ header, mainText, link, linkName}) => (
  <div className='blog_box futura'>
    <p className="text-bold blog_header">{header}</p>
    <p className='blog_main'>{mainText}</p>
    <p className='blog_main' style={{ marginBottom: '1rem' }}><a href={link} rel="noreferrer" target="_blank"><u>{linkName}</u></a></p>
  </div>

)

const Blog = () => (
  <div className="gpt3__blog section__padding_blog futura" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">How to prepare for Nifty<br /> Gateway Launches</h1>
    </div>
    <div className="gpt3__blog-container">
      <BlogBox header="1. Set up a Nifty Gateway account" mainText="Click sign up/login button located in the top right corner of Nifty Gateway's landing page." link="https://www.niftygateway.com" linkName="Nifty Gateway"/>
      <BlogBox header="2. Validate your account with Nifty Gateway" mainText="Nifty Gateway will follow up with an email to verify your account."/>
      <BlogBox header="3. Register your payment method" mainText="Nifty Gateway accepts Credit Card and Debit Card!"/>
      <BlogBox header="4. Buy your NFT when the auction goes live!" mainText="Visit this link to secure your Metaflare Collection NFT on Nifty Gateway!" link="https://www.niftygateway.com/publisher/metaflare" linkName="Metaflare Landing!"/>
    </div>
  </div>
);

export default Blog;