import React from 'react';
import Feature from '../../components/feature/Feature';
import './metaflareCollections.css';

const MetaflareCollections = () => {
  <div className="mf__whatmf section__margin" id="wmf">
    <div className="mf__whatmf-feature">
      <Feature title="What is Metaflare" text="Metaflare is taking charge of the digital transformation of art." />
    </div>
    <div className="mf__whatmf-heading">
      <h1>The possibilities are Endless</h1>
      <p>Explore the Possibilities</p>
    </div>
    <div className="mf__whatmf-container">
      <Feature title="Blockchain Expertise" text="We take care of the technology so that you don't have to. We have expertise in Blockchain technologies so that you can put the art first." />
      <Feature title="Connections" text="With connections across the art community you can be assured that you are joining a team of artists who are helping to revolutionize the field." />
      <Feature title="Market Research and Development" text="We stay ahead of the curve in this quickly moving digital environment so that the artists who we work with are always given an edge. " />
    </div>
  </div>
};

export default MetaflareCollections;