import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography, Card, CardMedia, CardContent, CardActions, Icon } from '@material-ui/core'
import CollectionsData from '../../../data/data';
import useStyles from '../styles';
import looksRare from '../icons/icon-darkbg@512.png';
import openSea from '../icons/Logomark-Blue.png';
import rarible from '../icons/R.jpg';
import nifty from '../icons/nifty.jpg';

const collection = CollectionsData;

const PreviewImage = ({ key, handleClick, focused, image }) => (
    <div className='preview_image_container' onClick={handleClick} id={key} focused={focused} style={{ cursor: "pointer" }}>
        {!focused &&
            <img src={image} id={key} style={{ height: "100px", width: "200px", borderRadius: "15px", opacity: "0.65" }}/>
        }
        {focused &&
            <img src={image} id={key} style={{ height: "100px", width: "200px", borderRadius: "15px" }}/>
        }
    </div>
)

const Product = () => {
    const classes = useStyles;
    const { collectionId } = useParams();
    const [pointer, setPointer] = useState(collection.collections[collectionId].url[0]);
    const [focused, setFocused] = useState([true, false, false, false]);

    let foundImage;
    let index;

    
    const images = collection.collections[collectionId].url
    
    const handleClick = (e) => {
        const url = e.target.src
        // console.log(e.target)
        foundImage = images.find((image) => image === String(url));
        // console.log(foundImage)
        index = images.findIndex((image) => image === url);
        // console.log(index)
        // const newImages = images.filter((image) => image !== e.target.src);
        setPointer(e.target.src)
        if (index == 0){setFocused([true, false, false, false])}
        else if (index == 1){setFocused([false, true, false, false])}
        else if (index == 2){setFocused([false, false, true, false])}
        else if (index == 3){setFocused([false, false, false, true])}
    }
    
    
    return (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <div style={{ width: '75%', marginBottom: '4rem', marginTop: '4rem' }}>
                <Card elevation={2}>
                    <CardContent className="futura">
                        <Typography gutterBottom variant="h5" component="div">
                            The <strong>{collection.collections[collectionId].name}</strong> Collection
                        </Typography>
                        <Typography component="div">
                            By <strong>{collection.collections[collectionId].artist}</strong>
                        </Typography>
                        <Typography component="div">
                            {collection.collections[collectionId].desc}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        height="400"
                        image={pointer}
                        alt={`${collection.collections[collectionId].name} Image`}
                        />
                    <CardContent className="main__text">
                        <Typography gutterBottom variant="h5" component="div">
                            The <strong>{collection.collections[collectionId].name}</strong> Collection
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginRight: "auto", marginLeft: "auto" }}>
                            {collection.collections[collectionId].url.slice(0, 4).map((image, i) => (
                                <PreviewImage image={image} handleClick={handleClick} key={i} focused={focused[i]}/>
                            ))}
                        </div>
                        <Typography component="div" variant="h6">
                            <b>About this collection</b>
                        </Typography>
                        <Typography component="div" className="message">
                            {collection.collections[collectionId].message}
                        </Typography>
                        <Typography component="div" className="message">
                            {collection.collections[collectionId].story}
                        </Typography>
                        <Typography component="div" className="message">
                            Price: {collection.collections[collectionId].price}
                        </Typography>
                        <Typography component="div" className="message">
                            Date: {collection.collections[collectionId].date}
                        </Typography>
                        <Typography component="div" variant="h6">
                            <b>Purchase from this collection:</b>
                        </Typography>
                        {collection.collections[collectionId].looksRare ? (
                        <a href="https://looksrare.org/" target="_blank">
                            <Button>
                            <img style={{ height: '30px' }} src={looksRare}/>
                            </Button>
                        </a>
                        ) : null }
                        {collection.collections[collectionId].openSea ? (
                        <a href="https://opensea.io/" target="_blank">
                            <Button>
                            <img style={{ height: '30px' }} src={openSea}/>
                            </Button>
                        </a>
                        ) : null }
                        {collection.collections[collectionId].rarible ? (
                        <a href="https://rarible.com/" target="_blank">
                            <Button>
                            <img style={{ height: '30px' }} src={rarible}/>
                            </Button>
                        </a>
                        ) : null }
                        {collection.collections[collectionId].nifty ? (
                        <a href="https://www.niftygateway.com/publisher/metaflare" target="_blank" rel="noreferrer">
                            <Button>
                            <img style={{ height: '30px' }} src={nifty}/>
                            </Button>
                        </a>
                        ) : null }
                        
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default Product;