import React, { useEffect } from 'react';
import { Typography, Card, CardMedia, CardContent, CardActions } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import { img1, img2, img3, img4, img5, img6 } from './imports';
// import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import data from '../../data/data';
import useStyles from './styles';
import './styles.css';

const CollectionsHeader = () => {
    function findFeaturedData (data) {
        const featuredCollections = [];
        for (let i = 0; i <= data.collections.length; i++){
            var curCollection = data.collections[i];
            if (curCollection?.isFeatured) {
                featuredCollections.push(curCollection);
                console.log(featuredCollections);
            }
        }
        return featuredCollections;
    }

    const featuredData = findFeaturedData(data);


    const classes = useStyles();
    return (
        <div>
            <div className={classes.featuredDiv}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2.5rem' }}>
                    <Typography style={{ fontSize: '36px', fontWeight: 'bold', color: 'white', fontFamily: 'var(--font-family)' }} className="futura">Featured Collections and Artists</Typography>
                </div>
                <div className="collections_header">
                    {featuredData.map((collection) => (
                        <div key={collection.id} className="featureCard">
                            <Link to={`/collections/${collection.id}`} style={{textDecoration: 'none', color:'white'}} className="futura">
                            <img src={collection.url[0]} className={classes.media}/>
                            <div className="date_bar">
                            <div className="date_circle"/>
                                <div>
                                    <p className="futura" style={{ color: "white", marginLeft: "1rem", marginTop: "4px" }}>{collection.date}</p> 
                                </div>
                            </div>
                                <Typography className={classes.collectionTitle}>
                                    {collection.name}
                                </Typography>
                                <Typography className={`${classes.collectionDesc} futura`}>
                                    {collection.artist}
                                </Typography>
                                <CardActions style={{ justifyContent: 'right' }}>
                                </CardActions>
                            </Link>
                        </div>
                        )
                    )}
                </div>
            </div>
        </div>      
    )
}

export default CollectionsHeader;