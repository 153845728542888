const CollectionsData = {
    collections: [
        {
            name: 'Gus',
            artist: 'Clinton Hobart',
            url: [
                "/gus.png",
            ],
            video: [
                "/gus_live.mp4",
            ],
            desc: 'A collection of pieces from licensed Disney artist Clinton Hobart of a lovable plush frog named Gus',  
            message: 'Clinton Hobart is a well-known artist whose work spans a variety of genres including still life, cartoons, and Disney characters among so much more. This collection showcases Gus, a contemplative stuffed animal frog. This is a one-of-one mint for an animated Gus which comes with the original physical painting for a starting auction price of $15k.',
            id: '0',
            price: '$15k',
            date: "October 8th, 2022",
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false,
        },
        {
            name: "Gus Metakicks",
            artist: 'Clinton Hobart',
            url: [
                "/gus_outdoors.jpg",
                "/gus_metakicks.JPG",
            ],
            video: [
                "gus_kicks.mp4",
                "gus_outdoors.mp4",
            ],
            desc: 'A collection of pieces from licensed Disney artist Clinton Hobart of a lovable plush frog named Gus',  
            message: 'Clinton Hobart is a well-known artist whose work spans a variety of genres including still life, cartoons, and Disney characters among so much more. This collection showcases Gus, a contemplative stuffed animal frog. There is an open mint for Gus Metakicks for $199.99, offering real world utility for minters in the form of a limited edition custom pair of Metaflare Shoes.',
            id: '1',
            price: '$199.99',
            date: "October 8th, 2022",
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'Butterfly Effect',
            artist: 'Julia Ryan',
            url: [
                "/julia_ryan.png",
                "/julia_ryan_main.JPG"
                ],
            video: [
                "/butterfly_effect.mp4",
            ],
            desc: 'A collection of Original Butterfly Paintings from Julia Ryan',
            message: 'Coming Soon!',
            id: '2',
            price: '',
            date: 'November 19, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'Timeless',
            artist: 'Nygilia',
            url: [
                "/twitterbanner_nygilia_metaflare.jpg",
                "/nygilia_metaflare1.jpg",
                "/nygilia_metaflare2.jpg",
                "/nygilia_metaflare3.jpg",
                ],
            video: [
            ],
            desc: 'Timeless by Nygilia',
            message: 'Nygilia, also known as fantasyfuturegyal, is a mixed-media artist that specializes in blending 2D & 3D styles into experimental visuals. She uses Virtual Reality and combines it with illustration, photography, and animation. Her NFT art embodies the inspiration of Afrofuturism by reflecting abstraction and fantasy. As she creates her art, storytelling is a big factor. Nygilia uses her experience from playing video games, such as series like "Final Fantasy" and "Legend of Zelda", to heighten the depth of character design. Nygilia\'s love for African and Asian Art is very much relevant to her craft.',
            story: '\n The Story of "fantasyfuturegyal": "I was born in a multicultural family and grew up playing video games that were filled with dreams and immersive storytelling. While playing, I really became drawn to RPG type gaming and how the user experience is such a vital part. You become emotional and embrace all aspects of the setting, characters, and their purpose. My vision is to progress afro-styles through experimentation and create new ways of exploration." - Nygilia',
            id: '3',
            price: '$150 Hoodie, $50 T-Shirt, $5 NFT',
            date: 'October 31, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'Metaflare Mondays 001',
            artist: 'Kix',
            url: [
                "/tunnel.png",
                "/orbs.png",
                "/dancefloor.png",
                "/endlessenergy.png",
                ],
            video: [
            ],
            desc: 'A weekly drop from Kix X Metaflare',
            // message: 'The  SHADOWS open edition for $150 comes with a physical META hoodie. The $50 CONSTELLATION open edition comes with a physical T-shirt. Redemptions will be done through our website 44 days after launch. ALL NFTs purchased in this drop will act as METAFLARE access passes for future collaborative drops.',
            message: 'Check us out on Nifty Gateway every Monday for a fresh new drop.',
            story: '',
            id: '4',
            price: 'Tunnel of Paradise: $15, Orbs/Dance Floor: $5, Endless Energy: $1',
            date: 'December 5, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'Clinton T. Hobart x Metaflare II',
            artist: 'Clinton Hobart',
            url: [
                "/pooh_and_honey_full_print.jpg",
                ],
            video: [
            ],
            desc: 'An exclusive collection from Clinton Hobart',
            message: 'An adorable painting of Pooh bear and his pot of honey.',
            story: '',
            id: '5',
            price: 'Pooh and Honey: $5,000,000.00',
            date: 'December 9, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'Clinton T. Hobart Open Editions',
            artist: 'Clinton Hobart',
            url: [
                "/red_bow.png",
                "/gift_box.png",
                "/ornaments.png",
                ],
            video: [
            ],
            desc: 'An exclusive holiday collection from Clinton Hobart',
            message: 'These three holiday themed items are a perfect addition to your seasonal collection.',
            story: '',
            id: '6',
            price: 'Red Bow: $20, Gift Box: $5, Christmas Ornaments: $5',
            date: 'December 11, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'VERSUS: Open Editions Round 1',
            artist: 'Scott Hattox',
            url: [
                "/battle_vs_1.png"
                ],
            video: [
            ],
            desc: 'A Space-Themed battle scene by Scott Hattox',
            message: '',
            story: '',
            id: '7',
            price: 'VS.: $10',
            date: 'December 17, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'VERSUS: Ranked Auction Round 1',
            artist: 'Scott Hattox',
            url: [
                "/battle_vs_1.png"
                ],
            video: [
            ],
            desc: 'An animated Space-Themed battle scene by Scott Hattox',
            message: '',
            story: '',
            id: '8',
            price: 'THE DUEL: $148',
            date: 'December 17, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'Metaflare Mondays 002',
            artist: 'Kix',
            url: [
                "/impatience.png",
                "/enclosed_orb_of_light.png",
                "/silent_rotation.png",
                "/bloom.png",
                ],
            video: [
            ],
            desc: 'The second weekly drop from Kix X Metaflare',
            message: 'Check us out on Nifty Gateway every Monday for a fresh new drop.',
            story: '',
            id: '9',
            price: 'Impatience: $79, Enclosed Orb Of Light: $3.99, Silent Rotation: $0.99, Bloom: $5',
            date: 'December 19, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: '?',
            artist: 'Metaflare',
            url: [
                "/gm_mystery.png",
                "/gn_mystery.png",
                ],
            video: [
            ],
            desc: 'Two mystery boxes',
            message: 'Mint to find what is in store in these mystery boxes.',
            story: '',
            id: '10',
            price: 'GM: $0.74, GN: $0.59',
            date: 'December 27, 2022',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: true
        },
        {
            name: 'Metaflare Mondays 003',
            artist: 'Kix',
            url: [
                "/break_time.png",
                "/puffy_flower.png",
                "/flowers.png",
                "/Digital_pulse.png",
                "/curved_glass.png",
                "/tiger.png",
                ],
            video: [
            ],
            desc: 'The third weekly drop from Kix X Metaflare',
            message: 'Check us out on Nifty Gateway every Monday for a fresh new drop.',
            story: '',
            id: '11',
            price: 'Break Time: $30, Puffy: $13.50, Flowers: $2.80, Digital Pulse: $500, Hour Glass: $1.75, Tiger: $4.99',
            date: 'January 23, 2023',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'Metaflare Mondays 004',
            artist: 'Kix',
            url: [
                "/golden_pyramids.png",
                "/fracture.png",
                "/captivity_freedom.png",
                "/eye.png",
                "/disciple_of_time.png",
                ],
            video: [
            ],
            desc: 'The fourth weekly drop from Kix X Metaflare',
            message: 'Check us out on Nifty Gateway every Monday for a fresh new drop.',
            story: '',
            id: '12',
            price: 'Golden Pyramids: $4, Fracture: $1, Captivity/Freedom: $189, Eye: $2.85, Disciple Of Time: $34',
            date: 'January 30, 2023',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: true
        },
        {
            name: 'Zombie King',
            artist: 'Metaflare',
            url: [
                "/jawsnft.jpg",
                "/happy_dogs.png",
                ],
            video: [
            ],
            desc: 'A Metaflare Exclusive Original Anonymous Drop',
            message: 'Mint your Jaws and Happy Dogs nfts.',
            story: '',
            id: '13',
            price: 'Jaws: $1, Happy Dogs: $1',
            date: 'February 9th, 2023',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
        {
            name: 'High Fashion: Tales of Sidlukotini',
            artist: 'Vintagemozart',
            url: [
                "/Sidlukotini.jpg",
                "/Sidlukotini_2.jpg",
                "/Sidlukotini_3.jpg",
                "/Sidlukotini_4.jpg",
                ],
            video: [
            ],
            desc: '4 Original Ethereal items from Vintagemozart',
            message: 'Collect all 4!',
            story: '',
            id: '14',
            price: 'Sidlukotoni: $5, Sidlukotini 4: $210',
            date: 'March 3, 2023',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: true
        },
        {
            name: 'Clinton Hobart X Metaflare III Open Editions',
            artist: 'Clinton Hobart',
            url: [
                "/the_joker.jpg",
                "/funyuns.jpg",
                ],
            video: [
            ],
            desc: 'The Joker and Funyuns',
            message: 'Two classic images in the style of Clinton Hobart.',
            story: '',
            id: '15',
            price: 'The Joker: $29, Funyuns: $29',
            date: 'March 17, 2023',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: true
        },
        {
            name: 'HA HA HA Ranked Auction',
            artist: 'Clinton Hobart',
            url: [
                "/haha.png",
                ],
            video: [
            ],
            desc: 'HA HA HA',
            message: 'An animated Joker from Clinton Hobart.',
            story: '',
            id: '16',
            price: 'HA HA HA: $1',
            date: 'March 19, 2023',
            openSea: false,
            looksRare: false,
            rarible: false,
            nifty: true,
            instagram: "",
            website: "",
            isFeatured: false
        },
    ]
            
}
    
export default CollectionsData; 