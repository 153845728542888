import React, { useState, useEffect } from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { FaFacebookF, FaTwitter, FaTwitch, FaInstagram } from 'react-icons/fa';
import { AiFillShopping, AiOutlineWallet } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import detectEthereumProvider from '@metamask/detect-provider';


import logo from '../../assets/metaflare_white_logo.png';
import mobile from '../../assets/mobile.png';

import './headerNew.css';

const HeaderNew = () => {
  const isDesktop = useMediaQuery('(min-width:1100px)');
  const showSocial = useMediaQuery('(min-width:1100px)');
  const [toggleState, setToggleState] = useState(false);
  // const detectMetamask = async () => {
  //   const provider = await detectEthereumProvider();
  //   console.log(provider)
  //   if (provider) {
  //     // From now on, this should always be true:
  //     // provider === window.ethereum
  //     startApp(provider); // initialize your app
  //     console.log(provider.isMetaMask)
  //   } else {
  //     console.log('Please install MetaMask!');
  //   }
  // }
  
  // function startApp(provider) {
  //   // If the provider returned by detectEthereumProvider is not the same as
  //   // window.ethereum, something is overwriting it, perhaps another wallet.
  //   if (provider !== window.ethereum) {
  //     console.error('Do you have multiple wallets installed?');
  //   }
  //   // Access the decentralized web!
  // }


  // function connect() {
  //   window.ethereum
  //     .request({ method: 'eth_requestAccounts' })
  //     .then(handleAccountsChanged)
  //     .catch((err) => {
  //       if (err.code === 4001) {
  //         // EIP-1193 userRejectedRequest error
  //         // If this happens, the user rejected the connection request.
  //         console.log('Please connect to MetaMask.');
  //       } else {
  //         console.error(err);
  //       }
  //     });
  // }

  // function handleAccountsChanged(accounts) {
  //   let currentAccount = null;

  //   if (accounts.length === 0) {
  //     // MetaMask is locked or the user has not connected any accounts
  //     console.log('Please connect to MetaMask.');
  //   } else if (accounts[0] !== currentAccount) {
  //     currentAccount = accounts[0];
  //     // Do any other work!
  //   }
  // }

  // useEffect(() => {
  //   detectMetamask()
  // }, [])
  

  return (
  <div className='navbar space-between flex-row'>
    {isDesktop ? (
        <div className='flex-row text-white my-auto ml-4 p-2 futura'>
            <div className='f-18 ml-2 text-20'><Link to="/" underline="hover" color="white" rel="noreferrer">Home</Link></div>
            {/* <div className='f-18 ml-2 text-20'><Link href="#" underline="hover" color="white">Schedule</Link></div> */}
            {/* <div className='f-18 ml-2 text-20'><Link href="#" underline="hover" color="white">Merch</Link></div> */}
            <div className='f-18 ml-2 text-20'><Link to="/collections" underline="hover" color="white" rel="noreferrer">Collections</Link></div>
            <div className='f-18 ml-2 text-20'><Link to="/redeem" underline="hover" color="white" rel="noreferrer">Redeem</Link></div>
            <div className='f-18 ml-2 text-20'><a href="https://www.niftygateway.com/publisher/metaflare" underline="hover" color="white" rel="noreferrer" target="_blank">Mint</a></div>
        </div>
    )
  : (
    <div className='ml-4 mt-4'>
    {toggleState
        ? <RiCloseLine className="cursor-pointer" size={50} color={'white'} onClick={(() => setToggleState(false))}/>
        : <GiHamburgerMenu className="cursor-pointer" color={'white'} size={50} onClick={(() => setToggleState(true))}/>
      }
      {toggleState && (
        <div className='toggle-menu'>
          <div className='flex-col futura'>
            <div className='f-18 ml-2 text-20 mt-1'><Link to="/" underline="hover" color="white">Home</Link></div>
            {/* <div className='f-18 ml-2 text-20'><Link href="#" underline="hover" color="white">Schedule</Link></div> */}
            {/* <div className='f-18 ml-2 text-20'><Link href="#" underline="hover" color="white">Merch</Link></div> */}
            <div className='f-18 ml-2 text-20'><Link to="/collections" underline="hover" color="white" rel="noreferrer">Collections</Link></div>
            <div className='f-18 ml-2 text-20'><Link to="/redeem" underline="hover" color="white" rel="noreferrer">Redeem</Link></div>
            <div className='f-18 ml-2 text-20'><a href="https://www.niftygateway.com/publisher/metaflare" underline="hover" color="white" rel="noreferrer" target="_blank">Mint on Nifty Gateway</a></div>
            {/* <div className='f-18 ml-2 text-20'><Link href="#" underline="hover" color="white">Facebook</Link></div> */}
            <div className='f-18 ml-2 text-20'><a href="https://www.instagram.com/metaflareofficial/" underline="hover" color="white" rel="noreferrer">Instagram</a></div>
            <div className='f-18 ml-2 text-20 mb-1'><a href="https://twitter.com/meta_flare" target="_blank" underline="hover" color="white" rel="noreferrer">Twitter</a></div>
            {/* <div className='f-18 ml-2 text-20'><Link href="#" underline="hover" color="white">Twitch</Link></div> */}
            {/* <div className='f-18 ml-2 text-20 mb-1'><Link href="#" underline="hover" color="white">Cart</Link></div> */}
          </div>
        </div>
      )}
      </div>
  )}
  {
    isDesktop ? (
        <div>
            <img src={logo} style={{ width: '300px', marginRight: '2rem' }}/>
        </div>
    ) : (
      <div>
        <img src={mobile} style={{ width: '250px', marginTop: '1rem' }}/>
      </div>
    )
  }
  {
    showSocial ? (
        <div className='flex-row ml-8 mr-2'>
          <div className='my-auto text-20'>
            {/* <Link href="https://www.facebook.com/" rel="noreferrer" target="_blank"><FaFacebookF className='ml-2' style={{ color: 'white' }}/></Link> */}
            <a href="https://www.instagram.com/metaflareofficial/" rel="noreferrer" target="_blank"><FaInstagram className='ml-2 ml-10' style={{ color: 'white' }}/></a>
            <a href="https://twitter.com/meta_flare" rel="noreferrer" target="_blank"><FaTwitter className='ml-2' style={{ color: 'white' }}/></a>
            <a href="https://www.niftygateway.com/publisher/metaflare" rel="noreferrer" target="_blank"  className='ml-2 mr-4' ><AiFillShopping style={{ color: 'white' }}/></a>
            {/* <AiOutlineWallet  onClick={connect} color="white" cursor="pointer"/> */}
            {/* <Link href="https://www.twitch.tv/" rel="noreferrer" target="_blank"><FaTwitch className='ml-2' style={{ color: 'white' }}/></Link> */}
            {/* <MdOutlineAddShoppingCart height="30px" style={{ color: 'white', marginLeft: '2rem', marginRight: '2rem' }}/> */}
          </div>
        </div>
    ) : (
      <div>
      </div>
    )
  }
  </div>
  );
  };

export default HeaderNew;