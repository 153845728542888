import React, { useState } from 'react';
import './faq.css';
import { Typography, Paper, Button } from '@material-ui/core';
import { ClassNames } from '@emotion/react';
import { makeStyles } from '@material-ui/core/styles';
import { IoMdArrowDropdownCircle } from 'react-icons/io';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const QandA = ({ question, answer, handleClick, toggle }) => (
    <div style={{ paddingLeft: '1rem', paddingRight: '1rem'}}>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <h1>{question}</h1>
            </AccordionSummary>
            <AccordionDetails>
                <p style={{ fontSize: '18px' }}>{answer}</p>                     
            </AccordionDetails>
        </Accordion>
    </div>
)

const Faq = () => {
    const useStyles = makeStyles(() => ({
        paper: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
    }))
    const [toggle, setToggle] = useState(false);

    const handleClick = () => (
        setToggle(!toggle)
    )
    const classes = useStyles();

    return (
        <div className="section__padding" style={{ fontFamily: 'var(--font-family)' }} id="faq">
            <Paper elevation={2} className={classes.paper}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography style={{ color: 'white', fontWeight: 'bold', fontSize: '30px', padding: '1rem 1rem' }}>
                        Frequently Asked Questions
                    </Typography>
                </div>

                <QandA 
                    question="Q: What is an NFT?" 
                    answer="A: An NFT, or a Non-Fungible Token, is a one-of-a-kind digital asset that represents ownership of art, music, video-games, artists, concert tickets, etc. Each NFT contains unique data stored on the blockchain."
                    handleClick={handleClick}
                    toggle={toggle}
                />
                <div style={{ height: '1rem'}}/>
                <QandA 
                    question="Q: What is the Blockchain?" 
                    answer="A: A decentralized public ledger that keeps of all transactions connected to it. Put simply, it is a chain of data-blocks that contains financial information. "
                    handleClick={handleClick}
                    toggle={toggle}
                />
                <div style={{ height: '1rem'}}/>
                <QandA 
                    question="Q: Why are NFTs valuable?" 
                    answer="A: Digital scarcity. NFTs represent ownership in the metaverse, a nascent and rapidly growing space with tons of potential."
                    handleClick={handleClick}
                    toggle={toggle}
                />
                <div style={{ height: '1rem'}}/>
                <QandA 
                    question="Q: What are NFTs used for?" 
                    answer="A: They can be used as a token of ownership for any digital asset."
                    handleClick={handleClick}
                    toggle={toggle}
                />
                <div style={{ height: '1rem'}}/>
                <div style={{ paddingLeft:'1rem', paddingRight:'1rem' }}>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <h1>Q: Where can you buy NFTs?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            A: The most popular exchanges to purchase NFTs include <a href="https://opensea.io" target="_blank">OpenSea,</a> <a href="https://marketplace.axieinfinity.com/" target="_blank">Axie Marketplace</a>, <a href="https://rarible.com/" target="_blank">Rarible</a>, and <a href="https://niftygateway.com/" target="_blank">Nifty gateway</a>.
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div style={{ height: '1rem' }}/>
                <QandA 
                    question="Q: How are NFTs related to the blockchain?" 
                    answer="A: NFTs as well as cryptocurrencies are built on top of the blockchain. It provides the foundation for all digital assets."
                    handleClick={handleClick}
                    toggle={toggle}
                />
                <div style={{ height: '1rem' }}/>
                <QandA 
                    question="Q: Are NFTs the future?" 
                    answer="A: There is undoubtedly world-changing potential in NFTs. Non-Fungible-Token technology is brand new and comparable to the early stages of the internet. From artist to athletes to musicians, all can benefit by selling products directly to their fans digitally. It also provides them a way to build a community with their fans."
                    handleClick={handleClick}
                    toggle={toggle}
                />
                <div style={{ height: '1rem' }}/>
                <QandA 
                    question="Q: Why Metaflare?" 
                    answer="A: Crypto native founders are dedicated to helping you launch your NFT project on the right blockchain and using the optimal exchange."
                    handleClick={handleClick}
                    toggle={toggle}
                />
                <div style={{ height: '1rem' }}/>
                <div style={{ paddingLeft:'1rem', paddingRight:'1rem' }}>
                    <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <h1>Q: Have any more questions?</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            A: Contact us: @<a href="mailto:info@meta-flare.com">info@metaflare.com</a>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div style={{ paddingBottom: '2rem' }}/>
            </Paper>
        </div>
    )
}

export default Faq;